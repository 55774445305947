@import '../../global/colors.scss';
#Website{
  #PageHome{

    #BuyNowProduct{
      font-size: 0px;
    }
    .questionAndAnswers{
      li{
        //font-size: 15px;
        padding-bottom: 5px;
        .answerEmph{
          font-weight: 600;
        }
      }
    }
    .sectionText{font-size: 18px;}
    .phoneNumber{
      strong{
        font-weight: 600;
      }
    }
    .phone{
      padding-top: 10px;
      font-size: 22px;
    }
    h1{
      line-height: 50px;
    }
    h2{
      margin: 0;
    }
    h3{
      margin: 5px 0 0;
    }
    .subHeader{
      padding-top: 5px;
      padding-bottom: 20px;
      font-size: 20px;
    }
    .subHeader2{
      padding-top: 5px;
      padding-bottom: 10px;
      font-size: 18px;
      font-weight: 400;
    }



    #TopMainSection{
      min-height: 900px;
      align-items: center;
      display: flex;
      > .container{
        background-color: rgba(255, 255, 255, .7);
      }
      .preHeader{
        color: $color5;
      }
      .phoneNumber{
        strong{
          display: block;
          font-size: 35px;
          font-weight: 700;
          color: $color5;
          &:hover{
            color: $color1;
          }
        }
      }
      .bigPicture{
        padding-right: 25px;
        img{
          border-radius: 10px;
        }
      }

    }

    #SimpleSteps{
      background-image: url("../../../../global/img/background/background_SVG_3b.svg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: top right;
      .d-flex-800{
        display: none;
      }
      .d-none-800{
        display: flex;
      }
      .stepRow{
        justify-content: center;
        margin: 20px auto 20px;
        .imageColumn{
          max-width: 600px;
        }
        .textZone{
          max-width: 380px;
          .preHeader2{
            background-color: $color5;
            color: #FFF;
            font-weight: 700;
            width: 100px;
            text-align: center;
            border-radius: 7px;
            padding: 5px;
            margin-bottom: 10px;
          }
          h4{
            font-size: 1.6rem;
            font-weight: 600;
          }
          &.textZoneLeft{
            text-align: right;
            .preHeader2{
              margin: 0 0 10px auto;
            }
          }
        }
      }
      .smallCenterText{
        div{
          font-size: 16px;
        }
        margin: auto;
        max-width: 600px;
      }
    }


    #Testimonials{
      >div{
        box-shadow: 0 0 10px 10px inset rgba(0, 0, 0, .2);
      }
      .testimonialsBlogContentWrapper{
        text-align: center;
        max-width: 700px;
        margin: auto;
        background: white;
        padding: 50px 30px;
        border-radius: 10px;
      }
    }

    #PricingPlans{
      h3{
        color: $color5;
        strong{
          color: #333;
        }
      }
      ol{
        //margin-bottom: 0;
        li{
          font-size: 14px;
          padding-top: 10px;
          font-weight: 500;
        }
      }
    }

    #SloveProblem{

    }

    #HaveQuestions{

    }

    //font-weight: 760;
  }

  
  @media only screen and (max-width: 1450px){
    #PageHome{
      .row{
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;

      }
      // .container{
      //   padding-top: 40px;
      // }
      h1{
        line-height: 40px;
        font-size: 38px;
      }
      h2.sectionTitle2{
        font-size: 1.6rem;
      }
      #TopMainSection{
        min-height: 600px;
        .doctorImage.bigPicture{
          //max-width: 400px;
        }
      }
      #SimpleSteps{
        .stepImages{
          max-width: 100%;
          //max-width: 600px;
        }
      }
    }
  }



  @media only screen and (max-width: 1400px){
    #PageHome{
      #TopMainSection{
        //min-height: 600px;
      }
    }
  }

  @media only screen and (max-width: 991px){
    #PageHome{
      #TopMainSection{
        .doctorImage{
          margin: 0 auto 40px;
          text-align: center;
          &.bigPicture{
            padding-right: 0px;
          }
        }
      }
      #HaveQuestions, #SloveProblem, #PricingPlans{
        text-align: center;
        ol, li{
          text-align: left;
        }
        .buttonGroup{
          justify-content: center;
        }
      }
    }
  }



  @media only screen and (max-width: 800px){
    #PageHome{
      h1{
        line-height: 30px;
      }
      #TopMainSection{
        > .container{
          padding-top: 30px;
        }
        .doctorImage.bigPicture{
          margin: 0 auto 20px;
          max-width: 70%;
          text-align: center;
        }
        .phoneNumber{
          strong{
            font-size: 26px;
          }
        }
      }
      .phone{
        font-weight: 500;
        text-transform: uppercase;
        strong{
          display: block;
        }
      }
      #SimpleSteps{
        .d-flex-800{
          display: flex;
        }
        .d-none-800{
          display: none;
        }
        .stepRow{
          justify-content: center;
          margin: 20px auto 20px;
          .imageColumn{
            max-width: 100%;
            width: 100%;
            min-width: 90%;
          }
          .textZone{
            max-width: 100%;
            text-align: center;
            .preHeader2{
              background-color: $color5;
              color: #FFF;
              font-weight: 700;
              width: 100px;
              text-align: center;
              border-radius: 7px;
              padding: 5px;
              margin: 0 auto 10px;
            }
            h4{
              font-size: 1.6rem;
              font-weight: 600;
            }
            &.textZoneLeft{
              text-align: center;
              .preHeader2{
                margin: 0 auto 10px;
              }
            }
          }
        }
        .smallCenterText{
          margin: auto;
          max-width: 600px;
        }
      }
      #PricingPlans{
        .sectionTitle3{
          .spacerLine{
            display: none;
          }
          strong{
            display: block;
          }
        }
      }
      #HaveQuestions{
        #HeaderImage{
          padding-bottom: 30px;
        .headerImageWrapper{
            .img{
              margin: 0 auto 0px;
            }
        }
        }
      }
    }
  }
}