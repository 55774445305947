@import './colors.scss';
#Cart{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  width: 90%;
  max-width: 450px;
  background: #FFF;
  border-left: 1px solid #eee;
  display: flex;
  height: 100%;
  overflow: hidden;
  animation-duration: .5s;
  flex-direction: column;
  &.hidden{
    display: none;
  }
  .title{
    background: $color5;
    color: #fff;
    padding: 10px;
    font-size: 25px;
  }
  @media only screen and (max-width: 540px) {
    .title{      
      padding: 10px;
      font-size: 15px;
      font-weight: 600;
    }
  }
  .cartContent{
    flex: 1;
    overflow: scroll;
  }
  .buttonGroup{
    padding: 10px 20px 20px;
    justify-content: flex-end;
    button{
      margin-bottom: 0;
      margin-top: 0;
    }
  }
}

#CartContent{
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  #Products{
      padding: 0 0 20px;
      flex: 1;
      border-bottom: 1px solid #ddd;
      .productsTitle{
        font-weight: 600;
        font-size: 20px;
        padding-bottom: 20px;
      }
      .products{
        .product{
          padding-bottom: 10px;
          .image{
            width: 80px;
            border: 1px solid #ddd;
            img{
              max-width: 100%;
            }
          } 
          .productInfo{
            padding-left: 15px;
            flex: 1;
            .name{
              font-size: 14px;
              padding-bottom: 5px;
            }
            @media only screen and (max-width: 540px) {
              .name{
                font-size: 11px;
                font-weight: 500;
              }
            }
            .quantity{
              align-items: center;
              i{
                padding: 0 8px;
              }
              .quantityInput{
                input{
                  max-width: 60px;
                  font-size: 12px;
                }
              }
              .quantityPrice{
                font-weight: 500;
                font-size: 14px;
              }
              .quantityPriceDiscounted{
                text-decoration: line-through;
                padding-right: 6px;
                font-size: 14px;
                color: #656565;
              }
            }
          }
          .toss{
            cursor: pointer;
            &:hover{
              color: $color5;
            }
          }
        }
      }
    }
    

    #FinancingWrapper{
      text-align: center;
      border: 1px solid #6c41fe;
      padding: 5px;
      border-radius: 10px;
      #FinancingWrapperTitle{
        #FinancingWrapperTitle1{
          font-size: 17px; 
          font-weight: 600;
          strong{
            
          }
        }
        #FinancingWrapperTitle2{
          font-size: 14px; 
          font-weight: 600;
          background-color: $color4;
          color: #fff;
          max-width: 500px;
          margin: auto;
        }
        // @media only screen and (max-width: 1199px) {
        //   #FinancingWrapperTitle1{
        //     padding-top: 40px;
        //     font-size: 32px; 
        //   }
        //   #FinancingWrapperTitle2{
        //     font-size: 22px; 
        //   }
        // }
       strong{
        
       }
      }
      #BlerbWrapper{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-top: 0px;
        #AfterpayBlerb{
          .logo{
            img{
              
            }
          }
        }
        #AffirmBlerb{
          .logo{
            img{
            }
          }
        }
        .blerbWrapper{
          padding: 10px 0px;
          flex: 1;
          .blerb{
            border: 2px solid $color4;
            border-radius: 15px;
            padding: 10px 10px 30px;
            min-width: 190px;
            width: 45%;
            margin: auto;
            height: 100%;
            .logo{
              width: 100px;
              margin: auto;
              img{
                max-width: 100%;
              }
            }
            .info{
              font-size: 13px;
              strong{
                color: $color4;
                font-weight: 800;
              }
            }
            .smallText{
              font-size: 11px;
              max-width: 100%;
              margin: auto;
              line-height: 13px;
              padding-top: 5px;
            }
          }
        }
        @media only screen and (max-width: 481px) {
          .blerbWrapper{
            padding: 10px 0px 0px;
            flex: 1;
            min-width: 100%;
            .blerb{
              min-width: 0px;
              width: 96%;
              padding-bottom: 10px;
              .logo{
                //width: 180px;
                margin: auto;
              }
              .info{
                //font-size: 20px;
                strong{
                }
              }
              .smallText{
                padding-top: 2px;
              }
            }
          }
        }
      }

    }


    #Coupons{
      padding: 20px 0;
      border-bottom: 1px solid #ddd;
      .coupon{
        .couponTitle{
          padding-bottom: 10px;
        }
        .couponFunction{
          input{

          }
          button{
            margin: 0;
            padding: 6px 20px;
            min-width: 100px;
            margin-left: 10px;
            height: 38px;
          }
        }
      }
      .couponInfo{
        padding-top: 10px;
        font-size: 14px;
        font-weight: 600;
        .couponName{
          padding-left: 5px;
        }
      }
    }
    #Summary{
      padding: 20px 0 0;
      .summary{
        .summaryRow{
          padding-bottom: 10px;
          &.summaryTotal{
            border-top: 1px solid #000;
            padding-top: 20px;
            margin-top: 15px;
            .summaryTitle{
              font-weight: 700;
              font-size: 18px;
            }
            .summaryValue{
              font-weight: 600;
              font-size: 18px;
            }  
          }
          &.summaryDiscount{
            .summaryValue{
              font-weight: 600;
            }
          }
          .summaryTitle{
            flex: 1;
            font-weight: 600;
          }
          .summaryValue{
            flex: 1;
            text-align: right;
          }
        }
      }
    }
}


@media only screen and (max-width: 800px) and (min-width: 576px) {
}

@media only screen and (max-width: 575px) {
}

@media only screen and (max-width: 480px) {
}