@import '../../global/colors.scss';
#Website{
	#Content #PageBlog{
		background-color: #f2f6f7a8;
		.breadcrumbs{
			//background-color: #ffffff;
			padding: 20px 0;
			> .container{
				padding-top: 10px;
				padding-bottom: 10px;
			}
			.col2{
				.content{
					height: 100%;
				}
			}
			.breadCrumbTitle{
				font-size: 30px;
				font-weight: 700;
			}
			.breadCrumbsLinks{
				display: flex;
				align-items: center;
				justify-content: flex-end;
				height: 100%;
				ul.inlineLists{
					text-align: right;
					margin: 0;
					.link{
						list-style: none;
						display: inline-block;
						.breadCrumbLink{
							text-decoration: none;
							color: $color1;
							&:hover{
								color: $color5;
							}
							&.active{
								text-decoration: none;
								pointer-events: none;
								color: #000;
							}
						}
					}
					.spacer{
						list-style: none;
						display: inline-block;
						width: 20px;
						text-align: center;
					}
				}
			}
			
			
		}
		.sideMenuItem{
			margin-bottom: 30px;
			background-color: #fff;
			border-radius: 8px;
			padding: 20px;
			.sectionMenuTitle{
				font-size: 20px;
				font-weight: 700;
				padding-bottom: 10px;
			}
		}
		#Categories{
			ul.categoriesUl{
				margin: 0;
				padding: 0;
				li{
					margin: 0;
					padding: 0px 0;
					border-bottom: 1px solid #ddd;
					list-style: none;
					&:last-child{
						border-bottom: none;
					}
					a.categoryFilterLink{
						text-decoration: none;
						color: #333;
						font-weight: 500;
						font-size: 13px;
						padding: 10px 0;
						display: block;
						cursor: pointer;
						&:hover{
							color: $color1;
						}
						&.active{
							color: $color5;
						}
					}
				}
			}
		}
		#Keywords{
			.keywordsWrapper{
				margin: 0;
				padding: 0;
				a.keyword{
					text-decoration: none;
					color: #333;
					font-weight: 500;
					font-size: 13px;
					padding: 5px 10px 5px 0;
					display: inline-block;
					cursor: pointer;
					&:hover{
						color: $color1;
					}
					&.active{
						color: $color5;
					}
				}
			}
		}
		#RecentPosts{
			ul.recentPostsUl{
				margin: 0;
				padding: 0 10px;
				li{
					margin: 0;
					padding: 0px 0;
					list-style: none;
					&:last-child{
						border-bottom: none;
					}
					a{
						text-decoration: none;
						padding: 10px 0;
						display: block;
						cursor: pointer;
						&:hover{
							color: $color1;
							.numberPost .post{
								.title{
									color: $color1;
								}
								.date{
									color: $color1;
								}
							} 
						}
						&.active{
							color: $color5;
						}
						.numberPost{
							display: flex;
							.number{
								font-size: 30px;
								line-height: 30px;
								width: 25px;
								font-weight: 700;
								text-align: center;
							}
							.post{
								flex: 1;
								padding-left: 10px;
								.title{
									color: #333;
									font-weight: 600;
									font-size: 13px;
									line-height: 1rem;
									padding-bottom: 5px;
								}
								.date{
									font-size: 10px;
									font-weight: 600;
									color: #888888;
								}
							}
						}
					}
				}
			}
		}

		


		.headerZoneText{
			padding-bottom: 30px;
		}
		.blogPreviewLinks{
			.blogPreviewColumn{
				.blogPreview{
					padding: 0px 0 10px;
					margin-bottom: 30px;
					background-color: #FFF;
					box-shadow: 0 0 10px rgba(0,0,0,.1);
					border-radius: 8px;
					border: 1px solid rgba(0,0,0,0.2);
					&:hover{
						box-shadow: 0 0 10px rgba(0,0,0,.4);
						border: 1px solid rgba(0,0,0,0.25);
					}
					.blogPreviewImage{
						border-radius: 8px 8px 0 0 ;
						overflow: hidden;
						//height: 235px;
						max-height: 235px;
						img{
						//max-width: 100%;
							max-width: none;
							width: 100%;
							//height: 100%;
						}
					}
					.blogPreviewText{
						padding: 15px 20px;
						.blogCatAndDate{
							padding-bottom: 5px;
							.blogPreviewCategories{
								display: inline-block;
								font-size: 10px;
								font-weight: 600;
								color: #888888;
								padding-right: 5px;
							}
							.blogPreviewDate{
								display: inline-block;
								font-size: 10px;
								font-weight: 600;
								color: #888888;
							}
						}
						.blogPreviewHeader{
							padding-top: 5px;
							padding-bottom: 5px;
							margin-bottom: 0;
							font-size: 18px;
							color: $color5;
							font-weight: 600;
						}
						.blogPreviewSummary{
							font-size: 13px;
							font-weight: 400;
						}
						.nav-link{
							margin: 0;
							padding-left: 0;
						}
					}
				}
			}
		}
	}
	
	#PageBlogSingle{
		background-color: #f2f6f7a8;
		.breadcrumbsWrapper{
			margin-bottom: 40px;
			.title{
				display: none;
			}
			.breadcrumbs{
				display: flex;
				//border: 1px solid $color1;
				border-radius: 10px;
				padding: 5px 10px;
				font-size: 14px;
				font-weight: 500;
				.blogLink{
					color: $color1;
					text-decoration: none;
					&:hover{
						color: $color5;
					}
					i{
						font-size: 12px;
					}
				}
				.dividerIcon{
					padding: 0 10px;
					color: #ccc;
				}
				.lastLink{
		
				}
			}
		}
		.sideMenuItem{
			margin-bottom: 30px;
			background-color: #fff;
			border-radius: 8px;
			padding: 20px;
			.sectionMenuTitle{
				font-size: 20px;
				font-weight: 700;
				padding-bottom: 10px;
			}
		}
		#Categories{
			ul.categoriesUl{
				margin: 0;
				padding: 0;
				li{
					margin: 0;
					padding: 0px 0;
					border-bottom: 1px solid #ddd;
					list-style: none;
					&:last-child{
						border-bottom: none;
					}
					a.categoryFilterLink{
						text-decoration: none;
						color: #333;
						font-weight: 500;
						font-size: 13px;
						padding: 10px 0;
						display: block;
						cursor: pointer;
						&:hover{
							color: $color1;
						}
						&.active{
							color: $color5;
						}
					}
				}
			}
		}
		#Keywords{
			.keywordsWrapper{
				margin: 0;
				padding: 0;
				a.keyword{
					text-decoration: none;
					color: #333;
					font-weight: 500;
					font-size: 13px;
					padding: 5px 10px 5px 0;
					display: inline-block;
					cursor: pointer;
					&:hover{
						color: $color1;
					}
					&.active{
						color: $color5;
					}
				}
			}
		}
		#RecentPosts{
			ul.recentPostsUl{
				margin: 0;
				padding: 0 10px;
				li{
					margin: 0;
					padding: 0px 0;
					list-style: none;
					&:last-child{
						border-bottom: none;
					}
					a{
						text-decoration: none;
						padding: 10px 0;
						display: block;
						cursor: pointer;
						&:hover{
							color: $color1;
							.numberPost .post{
								.title{
									color: $color1;
								}
								.date{
									color: $color1;
								}
							} 
						}
						&.active{
							color: $color5;
						}
						.numberPost{
							display: flex;
							.number{
								font-size: 30px;
								line-height: 30px;
								width: 25px;
								font-weight: 700;
								text-align: center;
							}
							.post{
								flex: 1;
								padding-left: 10px;
								.title{
									color: #333;
									font-weight: 600;
									font-size: 13px;
									line-height: 1rem;
									padding-bottom: 5px;
								}
								.date{
									font-size: 10px;
									font-weight: 600;
									color: #888888;
								}
							}
						}
					}
				}
			}
		}
		.mainSection{
			margin-bottom: 40px;
			> .content{
				background-color: white;
				padding: 30px 40px;
				.mainImage{
					margin: -30px -40px 0 -40px;
				}
				.blogPostMeta{
					padding-bottom: 30px;
					.blogPostMetaDateCategory{
						color: #888888;
						font-size: 12px;
						font-weight: 600;
					}
					.blogPostMetaKeywords{
						color: #888888;
						font-size: 12px;
						font-weight: 500;
					}
				}
			}
		}
		h1{
			padding: 20px 0 10px;
			font-size: 35px;
		}
		
		h2{
			padding-top: 10px;
			font-weight: 600;
			font-size: 25px;
			line-height: 35px;
		}
		h3{
			padding-top: 10px;
			font-weight: 600;
			font-size: 20px;
		}
		p{
			font-size: 15px;
			font-weight: 400;
			line-height: 1.5;
			color: #555555;
		}
		blockquote{
			margin: 0px auto;
			padding: 40px 0;
			max-width: 80%;
			color: #000;
			text-align: center;
			p{
				font-weight: 500;
				color: #000;
			}
	
		}
	}


	@media only screen and (max-width: 700px) {
		#PageBlogSingle{
			.breadcrumbsWrapper{
				display: none;
				margin-bottom: 40px;
				.breadcrumbs{
					max-height: 0px;
					overflow: hidden;
					display: block;
					//border: 1px solid $color1;
					border-radius: 10px;
					padding: 10px 15px;
					font-size: 14px;
					font-weight: 500;
					
					.blogLink{
						color: $color1;
						text-decoration: none;
						display: block;
						&:hover{
							color: $color5;
						}
						i{
							font-size: 12px;
						}
					}
					.dividerIcon{
						padding: 0 10px;
						color: #ccc;
						display: none;
					}
					.lastLink{
			
					}
				}
			}
		}
	}
}