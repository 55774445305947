@import '../../global/colors.scss';
#SmilesClub{
  #PageCheckout{
    min-height: 100%;
    .pageCheckoutHeader{
      background-color: $color1;
      h1{
        margin: 0;
        padding: 20px 0;
        color: #FFFFFF;
      }
    }
    #SideSummaryColumn{
      > .content{
        border-right: 2px solid $color1;
        background: #fafcff;
      }
    }
    #CheckoutPageComponentLeftSide{
      padding-bottom: 40px;
      //min-height: 100%;
      
      display: flex;
      flex-direction: column;
      #CartContentWrapper{
        flex: 1;
        #CartContent{
          
        }
        #FloatingCartContentWrapper{
          position: fixed;
          z-index: 97;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          #FloatingCartContent{
            position: absolute;
            z-index: 100;
            background: white;
            margin: auto;
            left: 0;
            right: 0;
            top: -100%;
            transition: all 1s ease;
            max-width: 90%;
            border-radius: 0 0 25px 25px;
            height: 100%;
            &.active{
              top: -20px;
            }
            #CartContent{
              overflow: scroll;
              height: 100%;
              padding-top: 30px;
              padding-bottom: 20px;
              border-color: $color5;
              #Summary{
                padding: 10px;
                .summaryRow{
                  padding-bottom: 5px;
                  &.summaryTotal{
                    padding-top: 10px;
                    margin-top: 5px;
                    border-top: 1px solid #000;
                  }
                } 
              }
              #FinancingWrapper{
                margin-top: 20px;
                padding-top: 10px;
                #FinancingWrapperTitle{
                  #FinancingWrapperTitle1{
                    font-size: 20px; 
                    font-weight: 600;
                  }
                  #FinancingWrapperTitle2{
                    font-size: 14px; 
                    font-weight: 600;
                    background-color: $color4;
                    color: #fff;
                    max-width: 500px;
                    margin: auto;
                  }
                }
                #BlerbWrapper{
                  display: flex;
                  flex-direction: row;
                  flex-wrap: wrap;
                  justify-content: space-between;
                  padding-top: 0px;
                  #AfterpayBlerb{
                    .logo{
                      img{
                        
                      }
                    }
                  }
                  #AffirmBlerb{
                    .logo{
                      img{
                      }
                    }
                  }
                  .blerbWrapper{
                    padding: 10px 0px;
                    flex: 1;
                    .blerb{
                      border: 2px solid $color4;
                      border-radius: 15px;
                      padding: 10px 10px 30px;
                      min-width: 190px;
                      width: 95%;
                      margin: auto;
                      height: 100%;
                      .logo{
                        width: 130px;
                        margin: auto;
                        img{
                          max-width: 100%;
                        }
                      }
                      .info{
                        font-size: 13px;
                        strong{
                          color: $color4;
                          font-weight: 800;
                        }
                      }
                      .smallText{
                        font-size: 11px;
                        max-width: 100%;
                        margin: auto;
                        line-height: 13px;
                        padding-top: 5px;
                      }
                    }
                  }
                  @media only screen and (max-width: 515px) {
                    .blerbWrapper{
                      padding: 10px 0px 0px;
                      flex: 1;
                      min-width: 100%;
                      .blerb{
                        min-width: 0px;
                        width: 96%;
                        padding-bottom: 10px;
                        .logo{
                          width: 100px;
                          margin: auto;
                        }
                        .info{
                          //font-size: 20px;
                          strong{
                          }
                        }
                        .smallText{
                          padding-top: 2px;
                        }
                      }
                    }
                  }
                }
          
              }
            }
          }
          #BackgroundOverlay{
            position: fixed;
            z-index: 99;
            margin: auto;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            background-color: rgba(0,0,0,.7);
          }
        }
      }
    }
    #FinancingWrapper{
      margin-top: 40px;
      padding: 20px;
      text-align: center;
      #FinancingWrapperTitle{
        #FinancingWrapperTitle1{
          font-size: 30px; 
          font-weight: 600;
          strong{
            
          }
        }
        #FinancingWrapperTitle2{
          font-size: 22px; 
          font-weight: 600;
          background-color: $color4;
          color: #fff;
          max-width: 500px;
          margin: auto;
          max-width: 80%;
        }
        @media only screen and (max-width: 1199px) {
          #FinancingWrapperTitle1{
            //padding-top: 0px;
            font-size: 28px; 
          }
          #FinancingWrapperTitle2{
            font-size: 20px; 
            
          }
        }
       strong{
        
       }
      }
      #BlerbWrapper{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-top: 10px;
        #AfterpayBlerb{
          .logo{
            img{
              
            }
          }
        }
        #AffirmBlerb{
          .logo{
            img{
            }
          }
        }
        .blerbWrapper{
          padding: 20px 0px;
          flex: 1;
          .blerb{
            border: 2px solid $color4;
            border-radius: 15px;
            padding: 10px 10px 30px;
            min-width: 300px;
            width: 98%;
            margin: auto;
            height: 100%;
            .logo{
              width: 180px;
              margin: auto;
            }
            .info{
              font-size: 22px;
              strong{
                color: $color4;
                font-weight: 800;
              }
            }
            .smallText{
              font-size: 16px;
              max-width: 85%;
              margin: auto;
              line-height: 22px;
              padding-top: 5px;
            }
          }
        }
        @media only screen and (max-width: 1199px) {
          .blerbWrapper{
            padding: 10px 0px;
            flex: 1;
            .blerb{
              padding: 5px 7px 20px;
              min-width: 250px;
              .logo{
                width: 170px;
              }
              .info{
                font-size: 16px;
                strong{
                  font-size: 18px;
                }
              }
              .smallText{
                font-size: 14px;
              }
            }
          }
        }
        @media only screen and (max-width: 991px) {
          .blerbWrapper{
            padding: 10px 0px;
            flex: 1;
            .blerb{
              min-width: 0px;
              width: 96%;
              .logo{
                width: 180px;
                margin: auto;
              }
              .info{
                font-size: 20px;
                strong{
                }
              }
              .smallText{
                font-size: 14px;
                line-height: 20px;
                padding-top: 2px;
              }
            }
          }
        }
      }

    }
    #CheckoutPageComponentRightSide{
      padding-bottom: 40px;
    }
  }
  
  @media only screen and (max-width: 991px) {
    #PageCheckout{
      .pageCheckoutHeader{
        h1{
          font-size: 20px;
          padding: 10px 0;
        }
      }
      #SideSummaryColumn{
        > .content{
          border-right: none;    
          background: #fafcff;
        }
      }
      #CheckoutPageComponentLeftSide{
        border-right: none;      
        //max-height: 100px;
        padding-bottom: 0px;
        overflow: hidden;
        position: relative;
        //min-height: 80px;
        &.active{
          //max-height: 100%;
          #CartContentWrapper{
            #CartContent{
              
            }
          }
        }
        #CartContentWrapper{
          transition: margin-top 1s ease;
          //margin-top: calc( -100% );
          height: 100%;
          #CartContent{
            padding-bottom: 0px;
            padding-top: 0px;
            border: 1px solid $color1;
            border-radius: 0 0 25px 25px;
            #Summary {
              padding-top: 0;
              .summaryRow{
                padding-bottom: 20px;
                &.summaryTotal{
                  border: none;
                  padding-top: 20px;
                  margin-top: 0px;
                }
              }
            }
          }
        }
        .expandButton{
          position: absolute;
          bottom: 0px;
          right: 0px;
          left: 0px;
          z-index: 2;
          display: flex;
          align-items: center;
          justify-content: center;
          .button{
            margin: auto;
            display: block;
            padding-top: 4px;
            padding-bottom: 4px;
            border-radius: .6rem .6rem 0 0;
            //width: 100%;
          }
        }
      }//End of CheckoutPageComponentLeftSide
    }
  }
  @media only screen and (max-width: 600px) {
    #PageCheckout{
      .pageCheckoutHeader{
        h1{
        }
      }
      #CheckoutPageComponent{
        padding: 0;
        #CheckoutPageComponentRightSide{
          padding-bottom: 40px;
          #CheckoutBreadcrumbs{
            padding: 10px 0 30px;
          }
        }
      }
      // #CheckoutPageComponentRightSide{
      //   padding-bottom: 0px;
      // }
    }
  }
}




